import React from 'react'
import { graphql } from 'gatsby'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import { Container } from 'theme-ui'

import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block02'
import WhyChooseUs from '@solid-ui-blocks/Features/Block06'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import CallToAction from '@solid-ui-blocks/CallToAction/Block02/Block02'

import theme from '../_theme'

const imageFileNames = [
  'epoxy-1.jpg',
  'epoxy-2.jpg',
  'epoxy-3.jpg',
  'epoxy-4.jpg',
  'epoxy-5.jpg',
  'epoxy-6.jpg'
]

const images = imageFileNames.map(i => ({
  original: `/gallery/${i}`,
  thumbnail: `/gallery-thumbnail/${i}`
}))

const EpoxyFlooringGallery = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)
  console.log('>> Images - ', { images })

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Epoxy Flooring Gallery' />

      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='6' />
      <Hero content={content['hero']} reverse />
      <Divider space='4' />

      <Container variant='narrow'>
        <ImageGallery
          items={images}
          showPlayButton={false}
          thumbnailPosition='top'
        />
      </Container>

      <Divider space='5' />
      <CallToAction content={content['cta']} />
      <Divider space='2' />
      <WhyChooseUs content={content['why-choose-us']} />

      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query innerpageEpoxyFlooringGalleryBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/epoxy-floors-gallery", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default EpoxyFlooringGallery
